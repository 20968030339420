import React, { useState } from 'react';
import './App.css';

function App() {
  const [page, setPage] = useState('signup'); // Tracks the current page: 'signup', 'signin', or 'jobScraper'
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const [jobRoles, setJobRoles] = useState('');
  const [location, setLocation] = useState('');
  const [responseData, setResponseData] = useState('');

  const handleSignup = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://fropel.com/rasaapi/signup.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
          username,
          role: 'user',
          access: '0',
        }),
      });
      const data = await response.json();
      if (data.success) {
        alert(data.message);
        setPage('signin'); // Redirect to signin page
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Error signing up. Please try again.');
    }
    setLoading(false);
  };

  const handleSignin = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://fropel.com/rasaapi/login.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      if (data.success) {
        alert(data.message);
        setPage('jobScraper'); // Redirect to job scraper page
      } else {
        setError(data.message);
      }
    } catch (err) {
      setError('Error signing in. Please try again.');
    }
    setLoading(false);
  };

  const handleScrape = async (platform) => {
    setLoading(true);
    setResponseData('');
    setError('');

    const jobRolesArray = jobRoles.split(',').map((role) => role.trim());

    try {
      const response = await fetch(`http://ec2-100-26-35-253.compute-1.amazonaws.com:5000/scrape/${platform}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ job_roles: jobRolesArray, location }),
      });

      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setResponseData(JSON.stringify(data, null, 2));
        alert(`Scraping ${platform} completed successfully!`);
      }
    } catch (err) {
      setError(`Error scraping ${platform}: ${err.message}`);
    }

    setLoading(false);
  };

  const handleDownload = async (platform) => {
    setError('');
    setLoading(true);

    const filenameMap = {
      indeed: 'indeed_jobs.csv',
      naukri: 'naukri_jobs.csv',
      linkedin: 'linkedin_jobs.csv',
    };

    try {
      const response = await fetch(`http://ec2-100-26-35-253.compute-1.amazonaws.com:5000/download/${filenameMap[platform]}`, {
        method: 'GET',
      });

      if (!response.ok) {
        const errorData = await response.json();
        setError(errorData.error || 'Error downloading file');
        setLoading(false);
        return;
      }

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filenameMap[platform];
      a.click();
      setLoading(false);
    } catch (err) {
      setError(`Error downloading ${platform} file: ${err.message}`);
      setLoading(false);
    }
  };

  const renderArrayInput = (input) => {
    const items = input.split(',').map((item) => item.trim());
    return (
      <div className="array-display">
        {items.map((item, index) => (
          <span key={index} className="array-chip">
            {item}
          </span>
        ))}
      </div>
    );
  };

  if (page === 'signup') {
    return (
      <div className="App">
        <h1>Sign Up</h1>
        <input
          type="email"
          placeholder="Email (e.g., john.doe@example.com)"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password (minimum 8 characters)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="text"
          placeholder="Username (e.g., JohnDoe123)"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <button onClick={handleSignup} disabled={loading}>Sign Up</button>
        <p>Already signed up? </p>
        <button onClick={() => setPage('signin')}>Sign In</button>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  if (page === 'signin') {
    return (
      <div className="App">
        <h1>Sign In</h1>
        <input
          type="email"
          placeholder="Email (e.g., john.doe@example.com)"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password (minimum 8 characters)"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSignin} disabled={loading}>Sign In</button>
        <p>Don’t have an account?</p>
        <button onClick={() => setPage('signup')}>Sign Up</button>
        {error && <p className="error">{error}</p>}
      </div>
    );
  }

  return (
    <div className="App">
      <h1>Job Scraper</h1>
      <form>
        <textarea
          placeholder="Job Roles (comma-separated)"
          value={jobRoles}
          onChange={(e) => setJobRoles(e.target.value)}
        ></textarea>
        <div className="array-input-label">
          {jobRoles && renderArrayInput(jobRoles)}
        </div>

        <input
          type="text"
          placeholder="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
      </form>

      <div className="button-group">
        <div className="platform-buttons">
          <button onClick={() => handleScrape('indeed')}>Scrape Indeed</button>
          <button onClick={() => handleDownload('indeed')}>Download CSV - Indeed</button>
        </div>
        <div className="platform-buttons">
          <button onClick={() => handleScrape('naukri')}>Scrape Naukri</button>
          <button onClick={() => handleDownload('naukri')}>Download CSV - Naukri</button>
        </div>
        <div className="platform-buttons">
          <button onClick={() => handleScrape('linkedin')}>Scrape LinkedIn</button>
          <button onClick={() => handleDownload('linkedin')}>Download CSV - LinkedIn</button>
        </div>
      </div>

      {loading && (
        <div className="loader">
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}

      {error && (
        <div className="error-message">
          <h3>Error:</h3>
          <p>{error}</p>
        </div>
      )}

      {responseData && (
        <div className="response-data">
          <h3>Response Data:</h3>
          <pre>{responseData}</pre>
        </div>
      )}
    </div>
  );
}

export default App;
